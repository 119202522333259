import React from 'react';
import { graphql, Link } from 'gatsby';
import Footer from '../components/footer';
import Header from '../components/header';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

import kebabCase from 'lodash/kebabCase';

deckDeckGoHighlightElement();

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;

    const tagsData = frontmatter.tags ? frontmatter.tags : [];
    const tagsLength = tagsData.length - 1;
    const tags = tagsData.map((tag, index) => {
        return (
            <>
                <Link
                    key={`${tag}`}
                    to={`/tags/${kebabCase(tag)}/`}
                    className="text-green-300 inline-flex items-center mt-4"
                >
                    {tag}
                </Link>
                {index < tagsLength ? ', ' : ''}
            </>
        );
    });
    return (
        <main>
            <Header />
            <section className="text-gray-600 body-font overflow-hidden">
                <div className="container px-5 py-12 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                                {frontmatter.title}
                            </h1>
                        </div>
                        <div className="-my-8 divide-y-2 divide-gray-100">
                            <div className="blog-post-container">
                                <div className="blog-post">
                                    <h2>{frontmatter.date}</h2>
                                    <div
                                        className="mt-6 mb-24 blog-post-content"
                                        dangerouslySetInnerHTML={{
                                            __html: html,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {tags.length > 0 && <span>Tags: {tags} </span>}
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    );
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                tags
            }
        }
    }
`;
